import fetchWithTimeout from "./fetchWithTimeout"

const url = `https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.GATSBY_IP_GEOLOCATION_API_KEY}`

export default async function fetchGeoLocationData() {
  const storedGeo = JSON.parse(window.sessionStorage.getItem("geoData"))

  if (storedGeo) {
    return storedGeo
  }

  try {
    const response = await fetchWithTimeout(url)
    const data = await response.json()
    const geoData = {
      countryCode: data.country_code2,
      currencyCode: data.currency.code,
    }
    window.sessionStorage.setItem("geoData", JSON.stringify(geoData))
    return geoData
  } catch (err) {
    return { countryCode: "US", currencyCode: "USD" }
  }
}
