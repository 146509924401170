import React from "react"

import PropTypes from "prop-types"

function App({ children }) {
  const [isClient, setIsClient] = React.useState(false)

  React.useEffect(() => {
    setIsClient(true)
  }, [])

  return <React.Fragment key={isClient}>{children}</React.Fragment>
}

App.propTypes = {
  children: PropTypes.node.isRequired,
}

export default App
