export const languageKeys = {
  EN: "en",
  ES: "es",
  IT: "it",
  FR: "fr",
  DE: "de",
  PT: "pt",
  CS: "cs",
}

export const languages = [
  { code: languageKeys.DE, value: "Deutsch" },
  { code: languageKeys.EN, value: "English" },
  { code: languageKeys.ES, value: "Español" },
  { code: languageKeys.FR, value: "Français" },
  { code: languageKeys.IT, value: "Italiano" },
  { code: languageKeys.PT, value: "Português" },
  { code: languageKeys.CS, value: "Čeština" },
]
