import { createGlobalStyle } from "styled-components"

import PropTypes from "prop-types"

import { colors } from "src/ui/colors"

import "sanitize.css"

const Styles = createGlobalStyle`
  body {
    font-family: "FigTree", sans-serif;
    -webkit-font-smoothing: antialiased; /* Chrome, Safari */
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    transition: color .15s;
    color: ${colors.primary};
    text-decoration: none;
    font-weight: bold;
  }

  a:hover {
    text-decoration: underline;
  }
`

const GlobalStyles = ({ children }) => {
  return (
    <>
      <Styles />
      {children}
    </>
  )
}

GlobalStyles.propTypes = {
  children: PropTypes.node.isRequired,
}

export default GlobalStyles
