import { createTheme } from "@material-ui/core/styles"
import { darken } from "polished"

import { colors, colorScale } from "./ui/colors"
import { spacing } from "./ui/spacing"

export default createTheme({
  palette: {
    primary: { main: colors.primary },
    secondary: { main: colorScale.hejmo[700] },
  },
  typography: {
    fontFamily: "FigTree, sans-serif",
    color: colors.textPrimary,
  },
  props: {
    MuiDialogTitle: {
      disableTypography: true,
    },
    MuiButton: {
      disableRipple: true,
      disableElevation: true,
    },
    MuiButtonGroup: {
      disableRipple: true,
      variant: "contained",
      color: "primary",
      disableElevation: true,
    },
    MuiToggleButton: {
      disableRipple: true,
    },
    MuiTab: {
      disableRipple: true,
    },
    MuiCheckbox: {
      disableRipple: true,
      color: "primary",
    },
    MuiPopover: {
      elevation: 5,
    },
    MuiFormControl: {
      variant: "outlined",
    },
    MuiSelect: {
      variant: "outlined",
    },
    MuiTextField: {
      variant: "outlined",
    },
    MuiTooltip: {
      placement: "top",
      arrow: true,
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        fontSize: "0.875rem",
      },
    },
    MuiInputBase: {
      root: {
        fontWeight: 500,
        fontSize: "0.875rem",
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        letterSpacing: "0px",
        fontWeight: 600,
        borderRadius: "8px",
        color: colors.primary,
        transition:
          "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      },
      contained: {
        backgroundColor: "white",
        color: colors.primary,
        "&:hover": {
          backgroundColor: "white",
          color: darken(0.2, colors.primary),
        },
      },
      containedPrimary: {
        "&:hover": {
          color: "white",
        },
      },
      outlined: {
        borderWidth: "1px",
        color: "#3a6085",
        borderColor: colors.primary,
      },
      outlinedPrimary: {
        borderColor: colors.primary,
        borderWidth: "1px",
        color: "#3a6085",
        "&:hover": {
          borderWidth: "1px",
          borderColor: darken(0.1, colors.primary),
        },
      },
      containedSizeLarge: {
        paddingTop: spacing.S,
        paddingBottom: spacing.S,
        paddingLeft: spacing.XL2,
        paddingRight: spacing.XL2,
      },
      textSizeLarge: {
        paddingTop: spacing.S,
        paddingBottom: spacing.S,
      },
      outlinedSizeLarge: {
        paddingTop: spacing.S,
        paddingBottom: spacing.S,
      },
    },
    MuiToggleButton: {
      root: {
        textTransform: "none",
        letterSpacing: "0px",
        color: "inherit",
      },
      label: {
        color: colors.textPrimary,
      },
    },
    MuiPaper: {
      root: {
        color: colors.textPrimary,
      },
      rounded: {
        borderRadius: "16px",
      },
    },
    MuiDialogTitle: {
      root: {
        padding: `${spacing.XL} ${spacing.XL2} ${spacing.XS}`,
        fontSize: "1.5rem",
        fontWeight: 500,
      },
    },
    MuiDialogContentText: {
      root: {
        color: "inherit",
        opacity: 0.85,
      },
    },
    MuiDialogContent: {
      root: {
        padding: `${spacing.XS} ${spacing.XL2}`,
      },
    },
    MuiDialogActions: {
      root: {
        padding: `${spacing.L}`,
        flexFlow: "wrap",
      },
    },
    MuiFormControlLabel: {
      label: {
        fontWeight: 500,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "0.875rem",
        backgroundColor: colors.primaryLight,
        color: colors.textPrimary,
        boxShadow:
          "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
        padding: "0.875rem",
        borderRadius: "16px",
      },
      arrow: {
        color: colors.primaryLight,
      },
    },
  },
})
