exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-airbnb-auth-js": () => import("./../../../src/pages/airbnb/auth.js" /* webpackChunkName: "component---src-pages-airbnb-auth-js" */),
  "component---src-pages-airbnb-host-offer-js": () => import("./../../../src/pages/airbnb/host-offer.js" /* webpackChunkName: "component---src-pages-airbnb-host-offer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-next-gen-index-js": () => import("./../../../src/pages/next-gen/index.js" /* webpackChunkName: "component---src-pages-next-gen-index-js" */),
  "component---src-pages-order-confirmed-jsx": () => import("./../../../src/pages/order-confirmed.jsx" /* webpackChunkName: "component---src-pages-order-confirmed-jsx" */),
  "component---src-pages-sykes-cottages-index-js": () => import("./../../../src/pages/sykes-cottages/index.js" /* webpackChunkName: "component---src-pages-sykes-cottages-index-js" */),
  "component---src-pages-v-2-index-tsx": () => import("./../../../src/pages/v2/index.tsx" /* webpackChunkName: "component---src-pages-v-2-index-tsx" */)
}

