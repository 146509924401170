/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { MuiThemeProvider } from "@material-ui/core"
import { StylesProvider } from "@material-ui/core/styles"

import { TranslationProvider } from "src/utils/i18n/TranslationProvider"

import App from "./src/components/app"
import GlobalStyles from "./src/components/global-styles"
import theme from "./src/theme"

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to App as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <MuiThemeProvider theme={theme}>
      <GlobalStyles {...props}>
        <StylesProvider injectFirst>
          <TranslationProvider>
            <App {...props}>{element}</App>
          </TranslationProvider>
        </StylesProvider>
      </GlobalStyles>
    </MuiThemeProvider>
  )
}
