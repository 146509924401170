import { languageKeys } from "./languages"
import translations from "./translations.json"

export function getTranslationString(key, lang = languageKeys.EN) {
  let _lang

  try {
    _lang = lang.toLocaleLowerCase()
  } catch (err) {
    // FIXME: report error
  }

  return (
    translations[_lang]?.[key] || translations[languageKeys.EN]?.[key] || key
  )
}

export function interpolateString(translationString, interpolations) {
  if (!interpolations) {
    return translationString
  }

  return Object.keys(interpolations).reduce((prev, curr) => {
    return prev?.replace(`{{${curr}}}`, interpolations[curr])
  }, translationString)
}
